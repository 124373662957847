<template>
  <div class="edit-fp-video">
    <fd-form class="p-2" @submit="submitForm">
      <fd-form-section title="Edit Video">
        <fd-input
          v-model="videoForm.title"
          class="col-12 mb-2"
          label="Title"
          name="floorPlanVideoTitle"
          type="text"
        >
        </fd-input>

        <fd-input
          v-model="videoForm.videoLink"
          class="col-12 mb-2"
          placeholder="Enter a video link"
          name="floorPlanVideo"
          :validators="[validator.required, validator.website]"
        ></fd-input>
        <video-embed
          css="embed-responsive-16by9"
          :src="videoForm.videoLink"
        ></video-embed>
      </fd-form-section>

      <div class="col-12 d-flex justify-content-end">
        <button type="button" class="btn mt-1" @click="$emit('cancel')">
          Cancel
        </button>
        <button class="btn main mt-1 ml-1">Update</button>
      </div>
    </fd-form>
  </div>
</template>

<script>
import FloorPlanVideoModel from "@/models/floorPlanVideo";
import {
  required,
  website
} from "@/components/GlobalComponents/FormComponents/Validator/rules";

export default {
  name: "EditFloorPlanVideo",
  components: {},
  mixins: [],
  props: {
    video: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      videoForm: {},

      validator: {
        required: required,
        website: website
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.videoForm = FloorPlanVideoModel.getToEditResponse(this.video);
  },
  methods: {
    async submitForm() {
      this.$emit(
        "submit",
        this.videoForm.id,
        FloorPlanVideoModel.postPayload(this.videoForm)
      );
    }
  }
};
</script>

<style lang="scss">
.edit-fp-video {
}
</style>
